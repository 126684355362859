<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
          <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }" >Inicio</router-link > /
          <router-link exact="" style="text-decoration: none" :to="{ name: 'opticasPanel' }" >Opticas</router-link > /
          <router-link exact="" style="text-decoration: none" :to="{ name: 'opticasCrearPanel' }" >Crear</router-link >
      </v-col>

      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVA OPTICA </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12 text-center" v-show="!mostrarForm">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form @submit.prevent="guardar()" v-show="mostrarForm" :disabled="processForm" >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Nombre"
                    v-model="optica.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-autocomplete
                    @change="getDistritos"
                    dense
                    prepend-icon="mdi-database-search"
                    item-text="nombre"
                    item-value="idoptica_ciudad"
                    :items="resources.ciudades"
                    label="Ciudad"
                    v-model="optica.idoptica_ciudad"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-autocomplete
                    :loading="loadingDistritos"
                    dense
                    prepend-icon="mdi-database-search"
                    item-text="nombre"
                    item-value="idoptica_distrito"
                    :items="resources.distritos"
                    label="Distrito"
                    v-model="optica.idoptica_distrito"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-database-search"
                    :items="resources.posiciones"
                    label="Posición"
                    v-model="optica.posicion"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-database-search"
                    item-text="nombre"
                    item-value="idtipo_optica"
                    :items="resources.tiposOpticas"
                    label="Tipo de óptica"
                    v-model="optica.idtipo_optica"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                  <v-text-field
                    dense
                    label="Telefono de contacto"
                    v-model="optica.telefono_contacto"
                  ></v-text-field>
                </v-col>


                <!-- <v-col cols="12" md="4" >
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-database-search"
                    :items="resources.tiposSede"
                    label="Tipos de sede"
                    v-model="optica.tipo_sede"
                  ></v-autocomplete>
                </v-col> -->

                <v-col cols="12" md="4" v-if="optica.tipo_sede == 'Sede' ">
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-database-search"
                    item-text="nombre"
                    item-value="idsede_principal"
                    :items="resources.sedesPrincipales"
                    label="Sede Principal"
                    v-model="optica.idsede_principal"
                  ></v-autocomplete>
                </v-col>

                <!-- <v-col cols="12" md="4" v-if="optica.tipo_sede == 'Principal' " style="padding: 0 1rem;">
                  <p class="my-0">¿En "+ visitados" ?</p>
                  <v-switch 
                    :true-value="1" 
                    :false-value="0" 
                    v-model="optica.en_mas_visitados" 
                    :label="optica.en_mas_visitados ? 'SI' : 'NO'" 
                    hide-details
                    inset
                    class="my-0"
                  />
                </v-col>

                <v-col cols="12" md="4"  v-if="optica.tipo_sede == 'Principal' && optica.en_mas_visitados">
                  <v-autocomplete
                    dense
                    prepend-icon="mdi-database-search"
                    :items="resources.posicionesMasVisitados"
                    label="Posición (+ visitados)"
                    v-model="optica.en_mas_visitados_posicion"
                  ></v-autocomplete>
                </v-col> -->

                <v-col cols="12" md="4" style="padding: 0 1rem;">
                  <p class="my-0">Etiqueta de Mas visitado:</p>
                  <v-switch 
                    :true-value="1" 
                    :false-value="0" 
                    v-model="optica.mas_visitado" 
                    :label="optica.mas_visitado ? 'SI' : 'NO'" 
                    hide-details
                    inset
                    class="my-0"
                  />
                </v-col>

             
                

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    outlined
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Dirección"
                    v-model="optica.direccion"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    outlined
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Referencia"
                    v-model="optica.referencia"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Link google maps"
                    v-model="optica.link_maps"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    outlined
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Horario"
                    v-model="optica.horario"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    type="text"
                    dense
                    label="Precio examen"
                    v-model="optica.precio_examen"
                  ></v-text-field>
                </v-col>

                <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="resources.mostrar"
                    label="Mostrar"
                    v-model="optica.mostrar"
                  ></v-select>
                </v-col> -->

                <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                    dense
                    label="Codigo descuento"
                    v-model="optica.codigo_descuento"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'opticasPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      resources: {
        ciudades: [],
        distritos: [],
        tiposOpticas: [],
        mostrar: [
          { texto: "SI", valor: 1, },
          { texto: "NO", valor: 0, },
        ],
        posiciones: [],
        posicionesMasVisitados: [],
        tiposSede: [ 
          // 'Principal', 
          'Sede'
        ],
        sedesPrincipales: [],
      },
      optica: {
        nombre: "",
        direccion: "",
        referencia: "",
        precio_examen: 0,
        telefono_contacto: "",
        horario: "",
        link_maps: "",
        codigo_descuento: "",
        idoptica_ciudad: "",
        idoptica_distrito: "",
        idtipo_optica: "",
        posicion: 1,
        mas_visitado: 0,
        en_mas_visitados: 0,
        en_mas_visitados_posicion: 1,
        tipo_sede: 'Sede',
        idsede_principal: null,
      },
      
      
      mostrarForm: false,
      processForm: false,
      listErrors: [],
      alertError: false,
      loadingDistritos: false,
      loaderImagen: false,
    };
  },

  methods: {
    async getDatos() {
      try {
        const response = await this.axios("api/panel/opticas/create");
        const data = response.data;
        const { ciudades, tipos_opticas, posiciones, sedes_principales } = data;
  
        this.resources.ciudades = ciudades;
        this.resources.tiposOpticas = tipos_opticas;
        this.resources.posiciones = posiciones;
        this.resources.posicionesMasVisitados = posiciones;
        this.resources.sedesPrincipales = [{idsede_principal: null, nombre: 'Sin sede principal'}].concat(sedes_principales);
        this.resources.distritos = [];
        
        this.mostrarForm = true;
      } catch (error) {
        console.log(error);
      } 

    },

    async getDistritos() {
      this.loadingDistritos = true;

      try {
        const response = await this.axios("api/panel/opticas/resources/distritos", { params: { 
          ciudad_id: this.optica.idoptica_ciudad
        }})
        const data = response.data;
  
        this.resources.distritos = data.distritos;

      } catch (error) {      
        console.log(error);
      } finally{
        this.loadingDistritos = false;
      }

      
    },

    guardar() {
      this.alertError = false;
      this.processForm = true;

      this.axios({
        method: "POST",
        url: "api/panel/opticas",
        data: this.serialize(this.optica),
      })
        .then((response) => {
          const {data} = response;

          this.$toasted.success(data.data, { icon: "mdi-check-bold", });
          this.$router.push({ name: "opticasPanel", });
          
        })
        .catch((error) => {
          const {status,data} = error.response;
          
          if (status === 422) {
            let arrErrors = data.errors;

            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
            return;
          } 

          this.$toasted.error("Ocurrio un error al guardar este registro", { icon: "mdi-close",});
          return;

        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("opticas.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", { icon: "mdi-close", });
      this.$router.push({ name: "inicioPanel", });
    }

    window.scrollTo(0, 0);
    this.getDatos();
  },
};
</script>
